<script setup lang="ts">
import { mdiCog, mdiFinance, mdiLogout, mdiWallet } from "@mdi/js"
import { useIntl } from "~/composables/intl"
import { useDevice } from "~/composables/useDevice"

const emit = defineEmits<{
  (e: "login"): void
  (e: "logout"): void
  (e: "toggleDrawer"): void
}>()

// TODO: Refactor, this component shouldn't be visible atm if not logged in
defineProps<{ isLoggedIn: boolean }>()

const { trans } = useIntl()

const { isMobile } = useDevice()
</script>

<template>
  <v-app-bar app density="compact">
    <v-container class="py-0">
      <div v-if="isMobile" class="d-flex align-center">
        <div style="width: 33%">
          <v-app-bar-nav-icon @click="$emit('toggleDrawer')" />
        </div>
        <div style="width: 33%" class="d-flex justify-center">
          <NuxtLink :to="{ name: 'index' }" class="align-self-center">
            <span class="main-link">
              <img src="@/assets/svg.svg" alt="get signals logo" />
            </span>
          </NuxtLink>
        </div>

        <div style="width: 33%"></div>
      </div>
      <div v-else class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <NuxtLink :to="{ name: 'index' }">
            <span class="main-link">
              <img src="@/assets/svg.svg" alt="get signals logo" />
            </span>
          </NuxtLink>
        </div>
        <div class="d-flex">
          <NuxtLink
            class="d-flex align-center ml-8"
            :to="{ name: 'securities' }"
          >
            <v-icon :icon="mdiFinance" />
            {{ trans("header.securities") }}
          </NuxtLink>
          <NuxtLink
            class="d-flex align-center ml-8"
            :to="{ name: 'portfolio' }"
          >
            <v-icon :icon="mdiWallet" />
            {{ trans("header.myPortfolio") }}
          </NuxtLink>
          <NuxtLink class="d-flex align-center ml-8" :to="{ name: 'settings' }">
            <v-icon :icon="mdiCog" />
            {{ trans("body.settings") }}
          </NuxtLink>
          <NuxtLink
            class="d-flex align-center ml-8"
            :to="{ name: 'index' }"
            @click="() => (isLoggedIn ? emit('logout') : emit('login'))"
          >
            <v-icon :icon="mdiLogout" />
            {{ isLoggedIn ? "Logout" : "Login" }}
          </NuxtLink>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>