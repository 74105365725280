<script setup lang="ts">
import { useAuth } from "#imports"
import Header from "@/components/Header.vue"
import { mdiCog, mdiFinance, mdiLogout, mdiWallet } from "@mdi/js"
import { onMounted, effect, ref } from "vue"
import { useOptionalSessionUser } from "~/composables/currentUser"
import { useIntl } from "~/composables/intl"
import { useDevice } from "~/composables/useDevice"

const { isMobile } = useDevice()

const auth = useAuth()
const { trans } = useIntl()

// Monitor the access token, if it's about to expire, do a token refresh?
onMounted(() => {
  // TODO: Instead of doing this every hour, do it on api client authentication error
  // and retry the request.. ask the user if we can reload to login?
  setInterval(() => auth.getSession(), 1 * 60 * 60 * 1000)
})

// on refresh error, go login
effect(() => {
  if (auth.data.value?.error === "RefreshAccessTokenError") {
    if (!window.confirm(trans("session.expired"))) {
      return
    }
    void auth.signIn("auth0", undefined, {
      // don't display the login prompt if already logged-in at auth0
      prompt: "",
    }) // Force sign in to hopefully resolve error
  }
})
const isDrawerOpen = ref<boolean>(false)

const user = useOptionalSessionUser()
</script>

<template>
  <v-app>
    <v-navigation-drawer v-if="isMobile" v-model="isDrawerOpen">
      <v-list>
        <v-list-item v-if="isMobile">
          <NuxtLink :to="{ name: 'securities' }">
            <v-icon :icon="mdiFinance" />
            {{ trans("header.securities") }}
          </NuxtLink>
        </v-list-item>
        <v-list-item v-if="isMobile">
          <NuxtLink :to="{ name: 'portfolio' }">
            <v-icon :icon="mdiWallet" />
            {{ trans("header.myPortfolio") }}
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'settings' }">
            <v-icon :icon="mdiCog" />
            {{ trans("body.settings") }}
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink
            :to="{ name: 'index' }"
            @click="() => (user ? auth.signOut() : auth.signIn('auth0'))"
          >
            <v-icon :icon="mdiLogout" />
            {{ user ? "Logout" : "Login" }}
          </NuxtLink>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <Header
      :is-logged-in="!!user"
      @toggle-drawer="isDrawerOpen = !isDrawerOpen"
      @login="() => auth.signIn('auth0')"
      @logout="() => auth.signOut()"
    />
    <v-main>
      <Suspense :timeout="500">
        <slot />
        <template #fallback>
          <Loader />
        </template>
      </Suspense>
    </v-main>
  </v-app>
</template>